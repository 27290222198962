const Forms = {
  init: () => {
    const urlParams = new URLSearchParams(window.location.search);
    let modalMessage = document.querySelector('div.success-message');
    if (urlParams.has('successfulRedirect') || modalMessage) {
      const paramValue = urlParams.get('successfulRedirect');
      if (!modalMessage) {
        modalMessage = document.createElement('div');
        modalMessage.className = 'success-message';
        const messageTextElement = document.createElement('span');
        messageTextElement.classList.add('message-text');
        messageTextElement.textContent = 'Loading...';
        modalMessage.appendChild(messageTextElement);
        const baseUrl = window.location.href.split('?')[0];
        const successMessageUrl = `${baseUrl}/getSuccessMessage?successfulRedirect=${paramValue}`;
        fetch(successMessageUrl)
          .then(response => {
            if (response.ok) {
              return response.text();
            }
            throw new Error('Network response was not ok');
          })
          .then(text => {
            messageTextElement.innerHTML = text;
          })
          .catch(() => {
            messageTextElement.textContent = 'Your form submission was successful.';
          });
      }
      const originalParent = modalMessage.parentNode;
      const originalNextSibling = modalMessage.nextSibling;
      const popupContainer = document.createElement('div');
      popupContainer.classList.add('popup-container');
      popupContainer.appendChild(modalMessage);
      const dismissButton = document.createElement('button');
      dismissButton.type = 'button';
      dismissButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
          <g id="Group_671" data-name="Group 671" transform="translate(-457 -414)">
            <rect id="Rectangle_36" data-name="Rectangle 36" width="60" height="60" transform="translate(457 414)" fill="#fff"/>
            <g id="Mobile_-_close" data-name="Mobile - close" transform="translate(479 436)">
              <path id="close" d="M37.1,21.5l1.4,1.4-7.143,7.142,2.15,2.15L38.414,37.1l-1.4,1.4-5.657-5.657-1.4-1.4-7.014,7.014-1.4-1.4,7.014-7.014L21.5,22.985l1.4-1.4,7.056,7.057L37.1,21.5Z" transform="translate(-21.5 -21.5)" fill="#01509f" fill-rule="evenodd"/>
            </g>
          </g>
        </svg>
      `;
      dismissButton.classList.add('dismiss-button');
      modalMessage.appendChild(dismissButton);
      document.body.appendChild(popupContainer);
      dismissButton.addEventListener('click', (e) => {
        e.preventDefault();
        popupContainer.remove();
        if (originalNextSibling) {
          originalParent.insertBefore(modalMessage, originalNextSibling);
        } else {
          originalParent.appendChild(modalMessage);
        }
      });
    }
    const forms = document.querySelectorAll('form:not(.userform)');
    const debounce = (func, delay) => {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
      };
    };
    const findAncestorByClass = (element, className) => {
      let parent = element.parentElement;
      while (parent && !parent.classList.contains(className)) {
        parent = parent.parentElement;
      }
      return parent;
    };
    const isVisible = (element) => {
      const style = window.getComputedStyle(element);
      return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
    };
    const scrollToFirstVisibleMessage = debounce((form) => {
      const messages = form.querySelectorAll('.message');
      const firstVisibleMessage = Array.from(messages).find(isVisible);
      if (firstVisibleMessage) {
        firstVisibleMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 300);
    const validateForm = (form) => {
      let hasErrors = false;
      form.querySelectorAll('[required]').forEach((field) => {
        const parent = findAncestorByClass(field, 'field');
        if (!parent) {
          console.warn("Field does not have a parent with the class 'field':", field);
        }
        let message = parent?.querySelector('span.message');
        const messageText = `"${parent?.querySelector('label')?.textContent || field.placeholder || field.name || 'This field'}" is required.`;
        if (!field.value.trim()) {
          field.classList.add('error');
          hasErrors = true;
          if (!message) {
            message = document.createElement('span');
            message.classList.add('message', 'required', 'error');
            parent?.appendChild(message);
          }
          message.textContent = messageText;
        } else {
          field.classList.remove('error');
          message?.remove();
        }
      });
      if (hasErrors) {
        scrollToFirstVisibleMessage(form);
      }
      return !hasErrors;
    };
    forms.forEach((form) => {
      form.querySelectorAll('[required]').forEach((field) => {
        field.addEventListener('input', () => {
          if (field.value.trim()) {
            field.classList.remove('error');
            const parent = findAncestorByClass(field, 'field');
            parent?.querySelector('span.message')?.remove();
          }
        });
      });
      const submitButton = form.querySelector('[type="submit"]');
      if (submitButton) {
        const validationButton = document.createElement('button');
        validationButton.type = 'button';
        validationButton.innerHTML = submitButton.innerHTML || submitButton.value;
        submitButton.classList.forEach((cls) => {
          validationButton.classList.add(cls);
        });
        submitButton.style.display = 'none';
        submitButton.insertAdjacentElement('afterend', validationButton);
        validationButton.addEventListener('click', () => {
          if (validateForm(form)) {
            submitButton.click();
          }
        });
      }
      form.addEventListener('submit', (event) => {
        if (!validateForm(form)) {
          event.preventDefault();
        }
      });
    });
  },
};

export default Forms;
